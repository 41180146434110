import differenceInYears from 'date-fns/differenceInYears'
import { isValidPhoneNumber } from 'react-phone-number-input'
import * as Yup from 'yup'
import { creditCardUtil } from '@/utils'

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const phoneNumber = Yup.string()
  .matches(phoneRegExp, 'Phone number is not valid')
  .min(10, 'Phone number is not valid')
  .max(10, 'Phone number is not valid')
export const phoneNumberWithAreaCode = phoneNumber
  .min(10, 'Phone number is not valid')
  .max(10, 'Phone number is not valid')
export const internationalPhone = Yup.string().test(
  'internationalPhoneNumber',
  'Invalid phone number',
  function (value = '') {
    if (!value) return true
    return isValidPhoneNumber(value)
  }
)
export const name = Yup.string().min(2, 'Too Short!').max(50, 'Too Long!')
export const nameWithCoupleCheck = name.test(
  'probableCoupleInvestment',
  'Investing as a couple is not currently allowed',
  (name = '') => !/(&| and )/.test(name.toLowerCase())
)
export const email = Yup.string().email('Invalid email')
export const simpleString = Yup.string()
  .min(2, 'Too Short!')
  .max(50, 'Too Long!')
export const city = simpleString.matches(
  /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'|\.))*[a-zA-Z\u0080-\u024F]*$/,
  `Must be letters`
)
export const postalCode = Yup.string()
  .matches(/^[0-9]+$/, 'Must be only digits')
  .min(5, 'Must be exactly 5 digits')
  .max(5, 'Must be exactly 5 digits')
const bool = Yup.boolean()

export const creditCard = Yup.object({
  fullName: name.required('Required'),
  cardNumber: Yup.string()
    .required('Required')
    .matches(/^[0-9]+$/, 'Must be digits')
    .test('luhn_check', 'Invalid card number', (cardNumber) => {
      if (!cardNumber) return false
      let nCheck = 0
      let bEven = false
      for (let n = cardNumber.length - 1; n >= 0; n--) {
        let nDigit = parseInt(cardNumber.charAt(n), 10)
        if (bEven) {
          if ((nDigit *= 2) > 9) nDigit -= 9
        }
        nCheck += nDigit
        bEven = !bEven
      }
      return nCheck % 10 === 0
    })
    .test('length-check', 'Invalid card number', (cardNumber) => {
      // Currently, our supported cards all require 16 digits
      // but return true if entered card is something else so we can
      // display card-type error message below
      const shouldBe16Digits = !!creditCardUtil.detectType(cardNumber || '')
      if (shouldBe16Digits) return cardNumber?.length === 16
      return true
    })
    .test(
      'card-type',
      'Must be Visa, Mastercard, or Discover',
      (cardNumber) => !!creditCardUtil.detectType(cardNumber || '')
    ),
  monthYear: Yup.string()
    .required('Required')
    .typeError('Invalid expiry')
    .max(5, 'Invalid expiry')
    .matches(/([0-9]{2})\/([0-9]{2})/, 'Invalid expiry')
    .test(
      'test-credit-card-expiration-date',
      'Must be future date',
      (expirationDate) => {
        if (!expirationDate) {
          return false
        }

        const today = new Date()
        const monthToday = today.getMonth() + 1
        const yearToday = today.getFullYear().toString().substr(-2)

        const [expMonth, expYear] = expirationDate.split('/')

        if (Number(expYear) < Number(yearToday)) {
          return false
        } else if (
          Number(expMonth) < monthToday &&
          Number(expYear) <= Number(yearToday)
        ) {
          return false
        }

        return true
      }
    )
    .test(
      'test-credit-card-expiration-date',
      'Invalid month',
      (expirationDate) => {
        if (!expirationDate) {
          return false
        }
        const [expMonth] = expirationDate.split('/')
        return Number(expMonth) <= 12
      }
    ),
  cvc: Yup.string()
    .required('Required')
    .matches(/^[0-9]+$/)
    .min(3, 'Must be exactly 3 digits')
    .max(3, 'Must be exactly 3 digits'),
})

export const plaid = Yup.object({
  plaidAccountId: Yup.string().required(),
  plaidToken: Yup.string().required(),
})

export const stripe = Yup.object({
  stripeCardNumber: Yup.string().required(),
  stripeCardType: Yup.string().required(),
})

export const ach = Yup.object({
  routingNumber: Yup.string()
    .matches(/^[0-9]+$/)
    .min(9, 'Must be at least 9 digits')
    .max(9, 'Must be no more than 9 digits')
    .test('routing_number_checksum', 'Invalid routing number ', (value) => {
      if (!value) return true
      const c = Array.from(value, Number)
      const checksum =
        3 * (c[0] + c[3] + c[6]) + 7 * (c[1] + c[4] + c[7]) + c[2] + c[5] + c[8]
      return checksum % 10 === 0
    })
    .required('Required'),
  accountNumber: Yup.string()
    .required('Required')
    .matches(/^[0-9]+$/)
    .min(5, 'Must be at least 5 digits')
    .max(17, 'Must be no more than 17 digits'),
  accountType: Yup.string().required('Required'),
})

export const birthday = simpleString
  .required('Required')
  .test('test_valid_date', 'Invalid Date', (birthday) => {
    return birthday?.replace(/\D/g, '').length === 8
  })
  .test('test_valid_month', 'Invalid Month', (birthday) => {
    if (birthday === undefined) return false
    const month = parseFloat(birthday?.split('-')[0])
    return month <= 12 && month > 0
  })
  .test('test_valid_day', 'Invalid Day', (birthday) => {
    if (birthday === undefined) return false
    const day = parseFloat(birthday?.split('-')[1])
    return day <= 31 && day > 0
  })
  .test('test_valid_year', 'Invalid Year', (birthday) => {
    if (birthday === undefined) return false
    const year = parseFloat(birthday?.split('-')[2])
    const current_year = new Date().getUTCFullYear()
    return year > 1900 && year < current_year
  })
  .test('test_old_enough', 'Must be 18 years old', (birthday = '') => {
    const [month, day, year] = birthday
      .split('-')
      .map((value) => parseInt(value, 10))
    const bday = new Date(year, month - 1, day)
    const today = new Date()
    const diffYears = differenceInYears(today, bday)
    return diffYears >= 18
  })

// Note: this is currently a thin-mint file-size limitation
// This may need to be extended to allow other file size limits
// in the future
export const FILE_SIZE_LIMIT = 5 * 1024 * 1024

export const files = Yup.array()
  .of(Yup.mixed<{ file?: File; size: number }>())
  .test(
    'fileSize',
    `Files must be less than ${FILE_SIZE_LIMIT / 1024 / 1024}MB`,
    (values) => {
      if (!values) return true
      return values.every((value) => {
        if (!value) return true
        return value?.file
          ? value.file.size < FILE_SIZE_LIMIT
          : value.size < FILE_SIZE_LIMIT
      })
    }
  )

export const singleFile = files.max(1, 'Only one file allowed')

export const ssn = Yup.string()
  .matches(/^[0-9]+$/, 'Must be digits')
  .min(9, 'Must be exactly 9 digits')
  .max(9, 'Must be exactly 9 digits')

export const profileSchema = Yup.object({
  firstName: name,
  lastName: name,
  dialCode: Yup.string(),
  phone: internationalPhone,
  country: simpleString,
  state: simpleString,
  displayAnonymous: bool,
})

export const nameInCreditsSchema = Yup.object({
  nameInCredits: Yup.string().when('showNameInCredits', {
    is: true,
    then: () => Yup.string().required('Required'),
  }),
  showNameInCredits: bool,
})
