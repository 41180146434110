import Cookies from 'universal-cookie'

export const ANGEL_USERID = '@ANGEL_USERID'
export const ANGEL_AUTH_TOKEN = '@ANGEL_AUTH_TOKEN'
export const ANGEL_REFRESH_TOKEN = '@ANGEL_REFRESH_TOKEN'

export const cookieOpts = {
  path: '/',
  secure: process.env.NODE_ENV !== 'development',
}

export const setPersistedAuth = (
  userId: string,
  token: string,
  refreshToken: string | null
) => {
  const cookie = new Cookies()
  cookie.set(ANGEL_USERID, userId, cookieOpts)
  cookie.set(ANGEL_AUTH_TOKEN, token, cookieOpts)
  refreshToken && cookie.set(ANGEL_REFRESH_TOKEN, refreshToken, cookieOpts)
}

export const getPersistedAuth = () => {
  if (typeof window === 'undefined') return { userId: null, token: null }

  const cookie = new Cookies()
  const userId = cookie.get(ANGEL_USERID)
  const token = cookie.get(ANGEL_AUTH_TOKEN)
  const refreshToken = cookie.get(ANGEL_REFRESH_TOKEN)

  return {
    userId,
    token,
    refreshToken,
  }
}

export const clearPersistedAuth = () => {
  const cookie = new Cookies()
  cookie.remove(ANGEL_USERID, cookieOpts)
  cookie.remove(ANGEL_AUTH_TOKEN, cookieOpts)
  cookie.remove(ANGEL_REFRESH_TOKEN, cookieOpts)
}

export const getDefaultContext = () => ({
  user: undefined,
  startAuth: () => null,
  openModal: () => null,
  closeModal: () => null,
  modalIsOpen: false,
  modalView: 'verifyAccount',
  setModalView: () => null,
  persistUser: () => null,
  clearUser: () => null,
  redirect: null,
  dismissable: true,
})
