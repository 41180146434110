import dynamic, { DynamicOptions } from 'next/dynamic'
import { AppFeatures } from '../../app-features'
import { Props } from './FadeInExperiment'

export const FadeInExperiment = dynamic(
  import('./FadeInExperiment')
    .then((mod) => mod.FadeInExperiment)
    .catch((err) =>
      // eslint-disable-next-line no-console
      console.error(`Failed to load the FadeInExperiment!`, err)
    ) as DynamicOptions<Props<keyof AppFeatures>>,
  { ssr: false }
)
